<template>
  <v-col v-if="progressStore.progress !== true" cols="12" sm="12">
    <v-row dense>
      <v-col cols="12" sm="12" md="12" lg="10" class="text-center">
        <span class="text-h5 nsfgrey--text"
          >More than one project was found to be associated with this account.
          <br />Please select a project below to continue.</span
        >
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="12" md="12" lg="10">
        <!--List-->
        <list-basic
          :show="projects && projects.length > 0 ? true : false"
          :list-data="projects"
          :title="'Projects'"
          :section="1.2"
          :button-title="'Project'"
          :actions="actions"
          @open-entry="openEntry($event)"
          @open-detail="openDetail($event)"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import ListBasic from "@/components/ListBasic";

export default {
  name: "ProjectSelector",
  components: {
    ListBasic,
  },
  mixins: [mixin],
  data() {
    return {
      actions: {
        menu: 0,
        view: 2,
        edit: 0,
        password: 0,
        invite: 0,
        email: 0,
      },
      projects: [],
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      progressStore: (state) => state.progressStore,
      projectsStore: (state) => state.projectsStore,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);

      await this.$store.dispatch("navigationStore/clearNavigation");
      await this.$store.dispatch("navigationStore/clearNavigationAdmin");
      window.localStorage.setItem("appMode", 3);
      await this.$store.dispatch("usersStore/setAppMode", 3);
      window.localStorage.removeItem("activeProject");
      window.localStorage.removeItem("activeUserType");
      //window.localStorage.removeItem('authUserType');
      await this.$store.dispatch("usersStore/clearActiveProject");
      let activeUser = await this.$store.getters["usersStore/getActiveUser"];

      await this.$store.dispatch(
        "usersStore/readActiveProjects",
        activeUser.id
      );

      activeUser = await this.$store.getters["usersStore/getActiveUser"];
      this.projects = this.$_uniqueProjectList(activeUser.projects);

      this.$store.dispatch("progressStore/set", false);
    },

    async openDetail(index) {
      let adminMode = this.$store.getters["usersStore/getAdminMode"];
      const activeUser = this.$store.getters["usersStore/getActiveUser"];
      const authUser = this.$store.getters["usersStore/getAuthUser"];
      let project = this.projects[index];
      window.localStorage.setItem("activeProject", JSON.stringify(project));
      await this.$store.dispatch("usersStore/setActiveProject", project);
      window.localStorage.setItem(
        "activeUserType",
        JSON.stringify(project.pivot.flag_user_type)
      );
      await this.$store.dispatch(
        "usersStore/setActiveUserType",
        project.pivot.flag_user_type
      );
      this.$store.dispatch("usersStore/clearAllUserLists");
      if (adminMode !== 1) {
        window.localStorage.setItem(
          "authUserType",
          JSON.stringify(project.pivot.flag_user_type)
        );
        await this.$store.dispatch(
          "usersStore/setAuthUserType",
          project.pivot.flag_user_type
        );
      }
      if (
        (project.pivot.flag_check !== 1 &&
          adminMode !== 1 &&
          project.pivot.flag_user_type === 1 &&
          authUser.flag_admin !== 1) ||
        (project.pivot.flag_check !== 1 &&
          adminMode !== 1 &&
          project.pivot.flag_user_type === 2 &&
          authUser.flag_admin !== 1) ||
        (project.pivot.flag_check !== 1 &&
          adminMode !== 1 &&
          project.pivot.flag_user_type === 3 &&
          authUser.flag_admin !== 1)
      ) {
        window.localStorage.setItem("appMode", 3);
        this.$store.dispatch("usersStore/setAppMode", 3);
        this.$router.push({ name: "profileCheck" });
      } else {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
